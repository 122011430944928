

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    data() {
        return {
            email: '',
            password: '',
        };
    },

    methods: {
        submit() {

            this.$store.dispatch('user/login', {
                email: this.email,
                password: this.password
            }).then(response => {
                if (response instanceof Error) {
                    if (response.response.status == 422){
                        this.$store.dispatch('setToastMessage', this.getErrorMessage(response))
                        this.$store.dispatch('setToastButton', '');
                    } else {
                        this.$store.dispatch('setToastMessage', response.response.data.message)
                        this.$store.dispatch('setToastButton', '');
                    }
                }
            });
        },
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class LoginForm extends Vue {}

